import AivatarButtonV2, { AivatarButtonV2Props } from '../../../../../../../components/buttons/AivatarButtonV2'

type Props = Omit<AivatarButtonV2Props, 'size'>

export default function OptionButton({ children, ...rest }: Props) {
  return (
    <AivatarButtonV2 size="S" type="button" className="w-[51px] whitespace-nowrap px-0" {...rest}>
      <span className="text-caption">{children}</span>
    </AivatarButtonV2>
  )
}
