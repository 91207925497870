import React from 'react'
import { useRecoilValue } from 'recoil'
import { selectedBoxIdsState } from '../../../../../recoil/texteditor/editor/selectors'
import ApplyAllOptions from './ApplyAllOptions'
import ResetOptions from './ResetOptions'
import SentenceBoxOptionController from './SentenceBoxOptionController'

export default function SentenceBoxOption() {
  const selectedSentenceBoxIds = useRecoilValue(selectedBoxIdsState)

  return (
    <>
      <div className="flex justify-between">
        <div className="text-headline03">문장 옵션</div>
        <ResetOptions />
      </div>
      <div className="text-headline05 my-[12px]">
        <span className="text-bcblue-500">{selectedSentenceBoxIds.length}개 문장</span> 선택
      </div>
      <SentenceBoxOptionController />
      <div className="min-h-[160px]">
        <ApplyAllOptions />
      </div>
    </>
  )
}
