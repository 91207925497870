/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useMemo, useCallback } from 'react'
import { Slider } from '@mui/material'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useDebounce } from 'react-use'
import { useParams } from 'react-router-dom'
import {
  editorValueState,
  focusedBoxValueState,
  selectedBoxIdsState,
  selectedBoxValueState,
} from '../../../../../../../recoil/texteditor/editor/selectors'
import {
  SENTENCEBOX_CATEGORY,
  SentenceBoxValuesForFetch,
} from '../../../../../../../recoil/texteditor/sentenceBox/atoms'
import useEditorCallbacks from '../../../../../../../recoil/texteditor/editor/useEditorCallbacks'
import useUpdateAudio from '../../../../../../../services/audio/useUpdateAudio'
import useDidMount from '../../../../../../../hooks/useDidMount'
import useAudioControllerCallbacks from '../../../../../../../recoil/audiocontroller/useAudioControllerCallbacks'
import { getDisplayOptionValue } from './getDisplayOptionValue'
import { isPlayingState } from '../../../../../../../recoil/audiocontroller/selectors'

const useOptionValueSlider = (optionKey) => {
  /** Recoilds */

  const didMount = useDidMount()
  // const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))
  const selectedBoxIds = useRecoilValue(selectedBoxIdsState)
  const setPlayed = useSetRecoilState(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'played',
    }),
  )
  const setIntervalPlayed = useSetRecoilState(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'intervalPlayed',
    }),
  )
  const { fetchAudioUpdate } = useUpdateAudio()

  const optionValue = useRecoilValue(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: optionKey,
    }),
  )

  const { setOptionValues } = useEditorCallbacks()
  const audioId = useRecoilValue(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'audioId',
    }),
  )

  const [value, setValue] = useState(-1)

  const { getAudioUpdateBody } = useAudioControllerCallbacks()
  const { projectId } = useParams()
  const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)

  // TODO 이거 상위로 빼고, 옵션 선택하는 코드(다중셀렉포함)랑 같이 커플링되야됨
  useDebounce(
    () => {
      // TODO 다중옵션 적용시 [focusedBoxId] -> selectedBoxIds로 변경
      const body = getAudioUpdateBody({ sentenceBoxIds: selectedBoxIds, projectId })
      if (!didMount) return
      // if (!audioId) return
      if (value === -1) return
      fetchAudioUpdate({ sentenceBoxIds: selectedBoxIds })
      setSentenceBoxValues({ ...sentenceBoxValues, ...body.attributes[0] })
      setPlayed(0) // 재생바를 초기화해야 새로운음성이 생성되고 재생됨
      setIntervalPlayed(0)
    },
    500,
    [value],
  )

  const onChangeOptionValue = useCallback(
    (e) => {
      const newOptionValue = e.target.value
      setValue(newOptionValue)
      setOptionValues({ optionKey, optionValue: newOptionValue })
    },
    [optionKey, setOptionValues],
  )

  const isInterval = useMemo(() => optionKey === 'space', [optionKey])

  const isPlaying = useRecoilValue(isPlayingState)
  const focusedOptionValue = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: optionKey,
    }),
  )

  const component = useMemo(
    () => (
      <Slider
        value={isPlaying ? focusedOptionValue : optionValue === 'Mixed' ? 0 : optionValue}
        onChange={onChangeOptionValue}
        min={isInterval ? 0.1 : 0.5}
        max={isInterval ? 99.9 : 1.5}
        aria-label="Always visible"
        size="small"
        step={0.1}
        valueLabelDisplay="auto"
        marks={((start, end, step) =>
          Array.from({ length: Math.ceil((end - start) / step) + 1 }, (_, i) =>
            parseFloat((start + i * step).toFixed(1)),
          ))(0.5, 1.5, 0.1).map((optionValue) => ({
          value: optionValue,
          label: getDisplayOptionValue(optionValue),
        }))}
        className="[&_.MuiSlider-thumb]:bg-bcblue-500 [&_.MuiSlider-rail]:bg-gs01-200 [&_.MuiSlider-track]:bg-bcblue-500 [&_.MuiSlider-markLabel]:text-caption [&_.MuiSlider-markLabel]:text-gs01-400"
        sx={{
          // color: "rgba(0,0,0,0.87)",
          width: 222,
          height: 5,
          marginTop: '-3px',

          '& .MuiSlider-thumb': {
            boxShadow: '0px 0px 0px 3px rgba(0, 108, 255, 0.46)',
            '&::after': {
              width: 'auto',
              height: 'auto',
            },
            '&.Mui-active': {},
          },
          '& .MuiSlider-rail': {
            height: 8,
            opacity: 1,
          },
          '& .MuiSlider-track': { height: 8, opacity: 1 },
          '& .MuiSlider-valueLabel': {},
          '& .MuiSlider-markLabel': { top: 30 },
          '& .MuiSlider-mark': {
            width: 4,
            height: 4,
            backgroundColor: 'white',
            borderRadius: '100%',
            opacity: 1,
            ':is(&[data-index="0"],&[data-index="10"])': {
              visibility: 'hidden',
            },
          },
        }}
      />
    ),
    [focusedOptionValue, isInterval, isPlaying, onChangeOptionValue, optionValue],
  )

  return {
    optionValue,
    component,
  }
}

export default useOptionValueSlider
