/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery } from '@tanstack/react-query'
import { TextSelection } from '@tiptap/pm/state'
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react'
import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { twJoin, twMerge } from 'tailwind-merge'
import { v4 as uuidV4 } from 'uuid'
import Checkbox from '../../../../../components/inputs/Checkbox'
import useDialog from '../../../../../hooks/useDialog'
import useLocaleList from '../../../../../hooks/useLocaleList'
import { editorState, paragraphBoxIdsState } from '../../../../../recoil/texteditor/editor/atoms'
import { sentenceBoxOptionValueState } from '../../../../../recoil/texteditor/sentenceBox/selectors'
import { defaultVoiceState } from '../../../../../recoil/workspace/selectors'
import { workspaceQueries } from '../../../../../services/workspace'
import TrashBin from '../../components/icons/TrashBinIcon'

export default function ParagraphComponent({ node, updateAttributes, getPos, editor, deleteNode }: NodeViewProps) {
  const paragraphBoxId = node.attrs['data-paragraphbox-id']
  const voiceId = node.attrs['data-voice-id']
  const checked = node.attrs['data-checked'] === 'true'
  const firstSentenceVoiceId = useRecoilValue(
    sentenceBoxOptionValueState({ id: node.children[0]?.attrs['data-sentencebox-id'], key: 'voiceId' }),
  )

  const { workspaceId } = useParams()
  const { data: currentVoice } = useQuery({
    ...workspaceQueries.detail(Number(workspaceId))._ctx.voices,
    select: (data) => {
      const voice = data.voices.find((item: any) => item.voice.id === Number(voiceId))
      return { name: voice.voice.name, locale: voice.voice.locale }
    },
    staleTime: 1000 * 60 * 5,
  })
  const [empty, setEmpty] = useState(true)

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!node.textContent) {
      setEmpty(false)
    } else {
      const { state, view } = editor
      const { selection, tr } = editor.state
      const { $from, $to } = selection
      const newPos = $from.start() // 이상한 커서 생기는거 방지
      const transaction = tr.setSelection(TextSelection.create(state.doc, newPos))
      view?.dispatch?.(transaction)

      setEmpty(true)
    }
  }, [editor, empty, node.textContent])

  const [index, setIndex] = useState<number | null>(null)
  const paragraphBoxIds = useRecoilValue(paragraphBoxIdsState)
  useEffect(() => {
    if (typeof getPos === 'function') {
      const pos = getPos()
      let paragraphCount = 0
      let currentIndex = -1

      // 문서 전체에서 paragraph 노드를 순회
      editor.state.doc.descendants((n: any, position: number) => {
        if (n.type.name === 'paragraph') {
          paragraphCount += 1

          if (position === pos) {
            currentIndex = paragraphCount
          }
        }
      })

      setIndex(currentIndex)
    }
  }, [node, getPos, editor, paragraphBoxIds])

  /**
   * 문단이 새로 생성되는 경우
   */
  useEffect(() => {
    const generatedParagraphBoxId = uuidV4()
    updateAttributes({ 'data-paragraphbox-id': generatedParagraphBoxId }) // UUID 할당
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { i18n } = useTranslation()
  const defaultVoice = useRecoilValue(
    defaultVoiceState({ language: i18n.language.startsWith('ko') ? 'ko-KR' : 'en-US' }),
  )

  // useEffect(() => {
  //   if (!voiceId && defaultVoice?.id) updateAttributes({ 'data-voice-id': defaultVoice.id })
  // }, [defaultVoice?.id, updateAttributes, voiceId])

  const setEditorState = useSetRecoilState(editorState)

  const changeCheckbox = (checked: boolean) => {
    editor.commands.setTextSelection(0) // 선택 범위를 문서의 첫 번째 위치로 이동
    editor.commands.blur() // 포커스 해제

    if (checked) {
      updateAttributes({ 'data-checked': 'true' })
    } else {
      updateAttributes({ 'data-checked': 'false' })
    }

    setEditorState((prev) => ({
      ...prev,
      focusedBoxId: null,
    }))
  }

  const handleCheckboxChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    changeCheckbox(e.currentTarget.checked)
    // set(editorState, (prev) => ({
    //   ...prev,
    //   focusedBoxId: null,
    // }))
  }

  const toggleCheckBox = () => {
    changeCheckbox(!checked)
  }

  // useEffect(() => {
  //   if (firstSentenceVoiceId) {
  //     updateAttributes({ 'data-voice-id': firstSentenceVoiceId })
  //   }
  // }, [firstSentenceVoiceId, updateAttributes])

  const dialog = useDialog()

  const handleDelete = () => {
    deleteNode()
  }

  const { localeList } = useLocaleList()

  return (
    <NodeViewWrapper className="border-b-gs01-200 relative border-b-[1px] py-[20px] pb-[18px]" {...node.attrs}>
      <div
        className={twMerge(
          'grid grid-cols-[auto_1fr] grid-rows-[auto_auto] gap-[30px_10px] rounded-[8px] px-[20px] py-[12px]',
          checked && 'bg-blue-20',
        )}
      >
        <div className="flex items-center" contentEditable={false}>
          <Checkbox onChange={handleCheckboxChanged} checked={checked} />
        </div>
        <div className="flex justify-between" contentEditable={false}>
          <div className={twMerge('grid grid-cols-[50px_auto]')}>
            <div className="text-headline05 flex items-center pl-[14px]">{index}</div>
            <button
              type="button"
              className={twJoin(
                'bg-blue text-gs01-950 text-subtitle01 inline-block rounded-[8px] border border-transparent bg-blue-100 px-[12px] py-[8px]',
                // checked && '!border-bcblue-500',
              )}
              onClick={() => toggleCheckBox()}
            >
              <b>{currentVoice?.name}</b> | {localeList[currentVoice?.locale as keyof typeof localeList]}
            </button>
          </div>
          <button
            type="button"
            onClick={() =>
              dialog.confirm({
                title: '문단 삭제하기',
                message: '해당 문단을 삭제하시겠습니까?',
                confirmButtonName: '삭제',
                onClickConfirm: () => {
                  dialog.close()
                  handleDelete()
                },
              })
            }
          >
            <TrashBin />
          </button>
        </div>
        <div className="col-start-2">
          <NodeViewContent
            className={twJoin(
              empty && 'before:text-body01 before:text-gs01-200 before:absolute before:leading-[24px]',
              empty && "[&:not(:focus)]:before:content-['문장을_입력해주세요.']",
            )}
          />
        </div>
      </div>
    </NodeViewWrapper>
  )
}
