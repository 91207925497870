/* eslint-disable import/prefer-default-export */

export function getDisplayOptionValue(value: number) {
  if (value === 1.0) {
    return '기본'
  }

  const result = parseFloat(((value - 1.0) * 10).toFixed())
  return result > 0 ? `+${result}` : result
}
