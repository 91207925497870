/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next'
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import useUpdateProject from '../../../../../services/project/useUpdateProject'
import { ReactComponent as SaveIcon } from '../../../../../assets/images/icon-save.svg'
import AivatarButton from '../../../../../components/buttons/AivatarButton'
import { CircularProgress } from '@mui/material'

function SaveButton() {
  const { t } = useTranslation('project')
  /** Recoils */

  /** Services */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { fetchProject, isLoading, isCallSuccess, shadowUpdating } = useUpdateProject()

  /** Event Handlers */
  const onClickSave = async () => {
    if (isLoading) return
    try {
      await fetchProject()
    } catch (error) {
      console.error(error)
    }
  }

  /** Memos */

  return (
    <AivatarButton type="button" variant="m4" onClick={onClickSave} disabled={shadowUpdating}>
      <span className="mr-[5px]">{t('저장하기')}</span>
      {shadowUpdating ? <CircularProgress size={19} color="inherit" /> : <SaveIcon />}
    </AivatarButton>
  )
}

export default SaveButton
