/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { MouseEventHandler, useContext, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { VoiceSelectContext } from '../..'
import useDisplayVoiceTag from '../../../../../../../../hooks/useDisplayTag'
import useLocaleList from '../../../../../../../../hooks/useLocaleList'
import { LocalStorage } from '../../../../../../../../utils/storage'
import IconFemale from '../icons/IconFemale'
import IconMale from '../icons/IconMale'
import IconLoading from './IconLoading'
import IconPause from './IconPause'
import IconPlay from './IconPlay'
import IconStarEmpty from './IconStarEmpty'
import IconStarFilled from './IconStarFilled'

type Props = {
  data: {
    id: number
    displayName: string
    language: string
    gender: string
    tags: string[]
    sampleSrc: string
    locale: string
  }
}

export default function VoiceItem({ data }: Props) {
  const { displayVoiceTag } = useDisplayVoiceTag()

  const getGenderDisplayName = (gender: string | null) => {
    switch (gender) {
      case 'male':
        return displayVoiceTag('남성')
      case 'female':
        return displayVoiceTag('여성')
      default:
        return gender
    }
  }

  const getGenderIcon = (gender: string | null) => {
    switch (gender) {
      case 'male':
        return <IconMale />
      case 'female':
        return <IconFemale />
      default:
        return null
    }
  }

  const [favorite, setFavorite] = useState(LocalStorage.favoriteVoiceList.includes(data.id))

  const toggleFavorite = () => {
    const { favoriteVoiceList } = LocalStorage

    if (favorite) {
      setFavorite(false)
      LocalStorage.favoriteVoiceList = favoriteVoiceList.filter((voiceId) => voiceId !== data.id)
    } else {
      setFavorite(true)
      LocalStorage.favoriteVoiceList = [...favoriteVoiceList, data.id]
    }
  }

  const [source, setSource] = useState<string | undefined>(undefined)
  const [playing, setPlaying] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClickFavorite: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()

    toggleFavorite()
  }

  const handleClickPlay: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()

    setSource(data.sampleSrc)

    if (playing) {
      setPlaying(false)
    } else {
      setPlaying(true)
      setTimeout(() => {
        audioRef.current?.play()
      })
    }
  }

  const onEnded = () => {
    setPlaying(false)
  }

  const audioRef = useRef<HTMLAudioElement>(null)

  const { selectedVoiceId, changeSelectedVoiceId, changeSelectedVoiceLocale } = useContext(VoiceSelectContext)

  const selected = selectedVoiceId === data.id

  const { localeList } = useLocaleList()

  return (
    <>
      <audio
        ref={audioRef}
        src={source}
        autoPlay={playing}
        style={{ display: 'none' }}
        onEnded={onEnded}
        onLoadStart={() => setLoading(true)}
        onLoadedData={() => setLoading(false)}
      />
      <button
        type="button"
        className={twMerge(
          'before:from-bcblue-500 group relative h-[128px] w-[309px] text-left before:absolute before:left-1/2 before:top-[14px] before:hidden before:h-[135px] before:w-[324px] before:-translate-x-1/2 before:bg-gradient-to-r before:to-green-400 before:opacity-30 before:blur-[29.5px]',
          !selected && 'hover:before:block',
        )}
      >
        <div
          className={twMerge(
            'border-gs01-200 relative z-10 h-full cursor-pointer rounded-[10px] border bg-white from-[#0086FF] to-[#1CD943] p-[2px]',
            selected && 'border-none bg-gradient-to-r',
          )}
          onClick={() => {
            changeSelectedVoiceId(data.id)
            changeSelectedVoiceLocale(data.locale)
          }}
        >
          <div
            className={twMerge(
              'grid h-full grid-cols-[1fr_auto] rounded-[8px] bg-white p-[18px]',
              selected && 'p-[19px]',
            )}
            // onClick={handleVoiceClicked}
          >
            <div>
              <div className="text-headline06 text-gs01-800">{data.displayName || ''}</div>
              <div className="text-body01 text-gs01-600 mt-[8px]">
                {localeList[data.locale as keyof typeof localeList]}
              </div>
              <div className="text-body01 text-gs01-600 mt-[4px]">
                <div className="flex items-center gap-[4px]">
                  {getGenderIcon(data.gender)}
                  {getGenderDisplayName(data.gender)}
                </div>
              </div>
              <div className="mt-[5px] flex gap-[6px]">
                {data.tags.length >= 0 &&
                  //! 태그 1번째: 남&여, 2번째: 나이대, 나머지: 톤 및 스타일. 나중에 태그에 성별 및 나이대 명시 안하면 slice 지우기
                  data.tags.slice(2).map((tag: string) => (
                    <div className="text-body01 text-gs01-600" key={`VoiceItemTag-${tag}`}>
                      {`#${displayVoiceTag(tag)}`}
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex flex-col items-end justify-between">
              <button type="button" onClick={handleClickFavorite}>
                {favorite ? <IconStarFilled /> : <IconStarEmpty />}
              </button>
              <button
                type="button"
                className="bg-bcblue-500 relative grid h-[36px] w-[36px] place-items-center rounded-full"
                onClick={handleClickPlay}
              >
                {playing ? <IconPause /> : <IconPlay />}
                {loading && <IconLoading className="absolute left-[3px] top-[3px] animate-spin" />}
              </button>
            </div>
          </div>
        </div>
      </button>
    </>
  )
}
