/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuItem } from '@mui/material'
import React, { useReducer, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { SelectedFilter } from '..'
import AivatarButtonV2 from '../../../../../../../components/buttons/AivatarButtonV2'
import Filter from './Filter'
import FilterSelect from './FilterSelect'
import IconFilter from './IconFilter'
import IconStarFilled from './IconStarFilled'
import IconStarOutlined from './IconStarOutlined'
import TabButton from './TabButton'

type Props = {
  selectedTabIdx: number
  onSelectTab: (idx: number) => void
  selectedFilters: SelectedFilter
  onSetFilter: (filters: SelectedFilter) => void
  onResetFilter: () => void
}

export default function Header({ selectedTabIdx, onSelectTab, selectedFilters, onSetFilter, onResetFilter }: Props) {
  const handleTabButtonClicked = (tabIdx: number) => {
    onSelectTab(tabIdx)
    onResetFilter()
  }

  const [filterShow, toggleFilterShow] = useReducer((show) => !show, false)

  const filterExists = selectedFilters.some((filters) => filters.length !== 0)

  return (
    <div>
      <div className="flex items-center gap-[23px]">
        <TabButton selected={selectedTabIdx === 0} onClick={() => handleTabButtonClicked(0)}>
          <span>즐겨찾기</span>
          {selectedTabIdx === 0 ? <IconStarFilled /> : <IconStarOutlined />}
        </TabButton>

        <div className="bg-gs01-200 h-[38px] w-[1px]" />

        <div className="flex">
          <TabButton
            selected={selectedTabIdx === 1}
            onClick={() => handleTabButtonClicked(1)}
            className="rounded-r-none"
          >
            범용 목소리
          </TabButton>
          <TabButton
            selected={selectedTabIdx === 2}
            onClick={() => handleTabButtonClicked(2)}
            className="-ml-[1px] rounded-l-none"
          >
            커스텀 목소리
          </TabButton>
        </div>
      </div>
      <div className="mt-[35px] flex justify-end gap-[24px]">
        <div className="relative">
          <button
            type="button"
            className={twMerge(
              'border-gs01-200 hover:border-bcblue-500 [&_path]:hover:fill-bcblue-500 relative grid h-[40px] w-[40px] place-items-center rounded-[4px] border',
              filterShow &&
                'border-bcblue-500 bg-bcblue-500 hover:border-bcblue-500 [&_path]:fill-white [&_path]:hover:fill-white',
              filterExists &&
                'border-bcblue-500 bg-bcblue-500 hover:border-bcblue-500 [&_path]:hover:fill-bcblue-500 hover:bg-white [&_path]:fill-white',
            )}
            onClick={toggleFilterShow}
          >
            <IconFilter />
            {filterExists && (
              <div className="absolute bottom-full left-full h-[15px] w-[15px] -translate-x-1/2 translate-y-1/2 rounded-full bg-orange-500" />
            )}
          </button>
          {filterShow && (
            <Filter
              className="absolute right-0 top-full"
              selectedFilters={selectedFilters}
              onSet={(filters) => {
                onSetFilter(filters)
              }}
              selectedTabIdx={selectedTabIdx}
              onReset={() => onResetFilter()}
              onClickAway={toggleFilterShow}
            />
          )}
        </div>
        {/* <div className="flex gap-[12px]">
          <FilterSelect
            value={selectedFilters.gender ?? ''}
            onChange={(e) => onSelectFilter('gender', e.target.value as string)}
          >
            <MenuItem value="">성별</MenuItem>
            <MenuItem value="남성">남성</MenuItem>
            <MenuItem value="여성">여성</MenuItem>
          </FilterSelect>
          <FilterSelect
            value={selectedFilters.age ?? ''}
            onChange={(e) => onSelectFilter('age', e.target.value as string)}
          >
            <MenuItem value="">나이대</MenuItem>
            <MenuItem value="10대">10대</MenuItem>
            <MenuItem value="20대">20대</MenuItem>
            <MenuItem value="30대">30대</MenuItem>
            <MenuItem value="40대">40대</MenuItem>
            <MenuItem value="50대">50대</MenuItem>
          </FilterSelect>
          <FilterSelect
            value={selectedFilters.style ?? ''}
            onChange={(e) => onSelectFilter('style', e.target.value as string)}
          >
            <MenuItem value="">톤 및 스타일</MenuItem>
            <MenuItem value="차분한">차분한</MenuItem>
            <MenuItem value="전문적인">전문적인</MenuItem>
          </FilterSelect>
        </div> */}

        {/* <AivatarButtonV2
          variant="contained-square"
          size="L"
          disabled={Object.values(selectedFilters).every((filterValue) => !filterValue)}
          onClick={onResetFilter}
        >
          필터 초기화
        </AivatarButtonV2> */}
      </div>
    </div>
  )
}
