/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useContext, useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { ReactComponent as ApplyAllIcon } from '../../../../../../assets/images/icon-expand.svg'
import useAivatarModal from '../../../../../../hooks/useAivatarModal'
import useEditorCallbacks from '../../../../../../recoil/texteditor/editor/useEditorCallbacks'
import useUpdateProject from '../../../../../../services/project/useUpdateProject'
import useAivatarDidMount from '../../../../../../hooks/useAivatarDidMount'
import AivatarButton from '../../../../../../components/buttons/AivatarButton'
import useDialog from '../../../../../../hooks/useDialog'
import AivatarButtonV2 from '../../../../../../components/buttons/AivatarButtonV2'
import { currentSelectedVoiceState } from '../../../../../../recoil/workspace/selectors'
import { EditorContext } from '../../../../ProjectLayoutV2'

const UPDATE_PROJECT_ACTION_TYPE = {
  NEW: 'NEW',
  UPDATE: 'UPDATE',
}

function ApplyAllParagraphOptions() {
  const { t } = useTranslation('project-option')
  const [actionType, setActionType] = useState(UPDATE_PROJECT_ACTION_TYPE.NEW)

  /** Recoilds */
  const { applyAllParagraphOptions } = useEditorCallbacks()

  /** Services */
  const { fetchProject } = useUpdateProject()

  const dialog = useDialog()
  /** Events */
  const onClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // openOptionSettingModal()
    dialog.confirm({
      title: (
        <span>
          <b className="text-bcblue-500">전체 문단</b>에 옵션 적용
        </span>
      ),
      message: '해당 옵션을 전체 문단에 적용 하시겠습니까?',
      confirmButtonName: '적용',
      onClickConfirm: onClickAllApply,
    })
  }

  // const onClickClose = () => {
  //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //   closeOptionSettingModal()
  // }

  const currentSelectedVoice = useRecoilValue(currentSelectedVoiceState)

  const { editor } = useContext(EditorContext)
  const onClickAllApply = () => {
    applyAllParagraphOptions({ voiceId: currentSelectedVoice?.id, language: currentSelectedVoice?.locale })
    setActionType(UPDATE_PROJECT_ACTION_TYPE.UPDATE)

    if (editor) {
      const { view } = editor ?? {}
      const { state } = view ?? {}
      if (!state) return
      if (!view) return

      const { tr } = state

      editor.state.doc.descendants((node, pos) => {
        if (node.type.name === 'paragraph') {
          const updatedAttrs = {
            ...node.attrs,
            'data-voice-id': currentSelectedVoice?.id,
          }
          tr.setNodeMarkup(pos, undefined, updatedAttrs)
        }
      })

      if (tr.docChanged) {
        view.dispatch(tr)
      }
    }

    dialog.close()
  }

  /** Modal */
  // const {
  //   modalComponent: OptionSettingModalComponent,
  //   openModal: openOptionSettingModal,
  //   closeModal: closeOptionSettingModal,
  // } = useAivatarModal({
  //   children: (
  //     <OptionSettingModal
  //       titleComponent={
  //         <p className="mb-[20px] px-[42px] text-center text-[24px] font-bold">
  //           <Trans t={t}>
  //             현재 선택된 문장박스의 옵션이 <span className="main_c">전체 적용</span>
  //             됩니다.
  //           </Trans>
  //         </p>
  //       }
  //       subTitle={t('현재 선택된 문장박스의 옵션이 전체 적용됩니다.')}
  //       onClickSetting={onClickAllApply}
  //       onClickClose={onClickClose}
  //       setButtonText={t('전체 적용')}
  //     />
  //   ),
  //   closeOnDocumentClick: true,
  // })

  /** Action Effects */
  useAivatarDidMount(async () => {
    if (actionType !== UPDATE_PROJECT_ACTION_TYPE.UPDATE) return
    setActionType(UPDATE_PROJECT_ACTION_TYPE.NEW)
    try {
      await fetchProject()
    } catch (error) {
      /* empty */
    }
    // closeOptionSettingModal()
  }, [actionType])

  /** Memos */

  return (
    <AivatarButtonV2
      type="button"
      variant="outlined-square"
      size="L"
      className="text-gs01-600 border-gs01-100 w-full"
      onClick={onClick}
    >
      {/* {OptionSettingModalComponent} */}
      <span>전체 문단에 적용하기</span>
    </AivatarButtonV2>
  )
}

export default ApplyAllParagraphOptions
