import React from 'react'

export default function IconPlus() {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="44" height="44" rx="22" fill="url(#paint0_linear_831_1893)" />
      <path
        d="M31.2602 20.99H23.0002V12.73C23.0002 12.18 22.5502 11.73 22.0002 11.73C21.4502 11.73 21.0002 12.18 21.0002 12.73V20.99H12.7402C12.1902 20.99 11.7402 21.44 11.7402 21.99C11.7402 22.54 12.1902 22.99 12.7402 22.99H21.0002V31.25C21.0002 31.8 21.4502 32.25 22.0002 32.25C22.5502 32.25 23.0002 31.8 23.0002 31.25V22.99H31.2602C31.8102 22.99 32.2602 22.54 32.2602 21.99C32.2602 21.44 31.8102 20.99 31.2602 20.99Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_831_1893"
          x1="6.5"
          y1="-9.50965e-07"
          x2="36.5"
          y2="44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006CFF" />
          <stop offset="1" stopColor="#18C95D" />
        </linearGradient>
      </defs>
    </svg>
  )
}
