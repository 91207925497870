/* eslint-disable import/order */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { Editor } from '@tiptap/core'
import { paragraphBoxIdsState, selectedParagraphBoxIdsState } from '../../../../../recoil/texteditor/editor/atoms'
import { workspaceVoiceValueState } from '../../../../../recoil/workspace/selectors'
import IconFemale from './VoiceSelectModal/components/icons/IconFemale'
import IconMale from './VoiceSelectModal/components/icons/IconMale'
// import LangSelect from './LangSelect'
// import VoiceSelect from './VoiceSelect'
import useDisplayVoiceTag from '../../../../../hooks/useDisplayTag'
import ApplyAllParagraphOptions from './ApplyAllParagraphOptions'
import useDialog from '../../../../../hooks/useDialog'
import VoiceSelectModal from './VoiceSelectModal'
import { EditorContext } from '../../../ProjectLayoutV2'
import ParagraphListTooltip from './components/ParagraphListTooltip'
import useLocaleList from '../../../../../hooks/useLocaleList'
import useUpdateProject from '../../../../../services/project/useUpdateProject'
import { Skeleton } from '@mui/material'

export default function ParagraphBoxOption() {
  const paragraphBoxIds = useRecoilValue(paragraphBoxIdsState)
  const selectedParagraphBoxIds = useRecoilValue(selectedParagraphBoxIdsState)

  // const currentSelectedVoice = useRecoilValue(currentSelectedVoiceState)

  const { displayVoiceTag } = useDisplayVoiceTag()

  const getGenderIcon = (gender: string | null) => {
    switch (gender) {
      case 'male':
        return <IconMale />
      case 'female':
        return <IconFemale />
      default:
        return null
    }
  }

  const getGenderDisplayName = (gender: string | null) => {
    switch (gender) {
      case 'male':
        return displayVoiceTag('남성')
      case 'female':
        return displayVoiceTag('여성')
      default:
        return gender
    }
  }

  const dialog = useDialog()

  const voices = useRecoilValue(workspaceVoiceValueState({ key: 'voices' }))
  const { editor } = useContext(EditorContext)

  const handleVoiceClicked = () => {
    if (!editor) return
    dialog.open(<VoiceSelectModal editor={editor as Editor} />)
  }

  const currentSelectedVoice = useMemo(() => {
    if (!editor) return null

    const selectedVoiceIds: string[] = []
    ;(editor as Editor).state.doc.descendants((node) => {
      if (node.type.name === 'paragraph' && node.attrs['data-checked'] === 'true') {
        selectedVoiceIds.push(node.attrs['data-voice-id'])
      }
    })

    const calculatedVoice = selectedVoiceIds.reduce(
      (acc, voiceId) => {
        const voice = voices.find((voiceItem: any) => voiceItem.id === voiceId)

        if (!voice) return null

        const calculatedVoiceId = acc.id === null ? voice.id : acc.id !== voice.id ? 'Mixed' : voice.id

        const result = {
          ...voice,
          id: calculatedVoiceId,
          displayName:
            acc.displayName === null
              ? voice.displayName
              : acc.displayName !== voice.displayName
                ? 'Mixed'
                : voice.displayName,
          alias: acc.alias === null ? voice.alias : acc.alias !== voice.alias ? 'Mixed' : voice.alias,
          locale: acc.locale === null ? voice.locale : acc.locale !== voice.locale ? 'Mixed' : voice.locale,
          gender: acc.gender === null ? voice.gender : acc.gender !== voice.gender ? 'Mixed' : voice.gender,
          tags: calculatedVoiceId === 'Mixed' ? 'Mixed' : voice.tags,
        }
        return result
      },
      {
        id: null as string | null,
        displayName: null as string | null,
        alias: null as string | null,
        locale: null as string | null,
        gender: null as string | null,
        tags: null as string[] | null,
      },
    )

    return calculatedVoice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, voices, selectedParagraphBoxIds])

  const { localeList } = useLocaleList()

  const { shadowUpdating } = useUpdateProject()

  return (
    <div>
      <div className="text-headline03 text-gs01-900">문단 옵션</div>
      <div className="text-headline05 text-gs01-800 mt-[12px] font-[500]">
        {paragraphBoxIds.length}개 문단 중{' '}
        <ParagraphListTooltip>
          <b className="text-bcblue-500 cursor-pointer hover:underline">{selectedParagraphBoxIds.length}개 문단</b>
        </ParagraphListTooltip>{' '}
        선택
      </div>
      <div className="mt-[12px] h-[128px]">
        {shadowUpdating ? (
          <Skeleton variant="rounded" height="100%" />
        ) : (
          <div className="border-gs01-100 group cursor-pointer rounded-[10px] border from-[#0086FF] to-[#1CD943] p-[2px] hover:border-none hover:bg-gradient-to-r">
            <div
              className="grid grid-cols-[1fr_auto] rounded-[8px] bg-white p-[18px] group-hover:p-[19px]"
              onClick={handleVoiceClicked}
            >
              <div>
                <div className="text-headline06 text-gs01-800">{currentSelectedVoice?.alias || ''}</div>
                <div className="text-body01 text-gs01-600 mt-[8px]">
                  {localeList[currentSelectedVoice?.locale as keyof typeof localeList]}
                </div>
                <div className="text-body01 text-gs01-600 mt-[4px]">
                  <div className="flex items-center gap-[4px]">
                    {getGenderIcon(currentSelectedVoice?.gender ?? null)}
                    {getGenderDisplayName(currentSelectedVoice?.gender ?? null)}
                  </div>
                </div>
                <div className="mt-[5px] flex gap-[6px]">
                  {Array.isArray(currentSelectedVoice?.tags) ? (
                    // @ts-ignore
                    currentSelectedVoice?.tags?.length >= 0 &&
                    currentSelectedVoice?.tags.slice(2)?.map((tag: string) => (
                      <div className="text-body01 text-gs01-600" key={`VoiceItemTag-${tag}`}>
                        {`#${displayVoiceTag(tag)}`}
                      </div>
                    ))
                  ) : (
                    <div className="text-body01 text-gs01-600">{`${currentSelectedVoice?.tags}`}</div>
                  )}
                </div>
              </div>
              <div className="flex items-center">
                <div className="bg-bcblue-500 grid h-[36px] w-[36px] place-items-center rounded-full">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.9134 7.9733C11.9134 8.14664 11.8468 8.3133 11.7201 8.44664L5.51342 14.6533C5.25342 14.9133 4.83342 14.9133 4.57342 14.6533C4.31342 14.3933 4.31342 13.9733 4.57342 13.7133L10.3134 7.9733L4.66675 2.3333C4.40675 2.0733 4.40675 1.6533 4.66675 1.3933C4.92675 1.1333 5.34675 1.1333 5.60675 1.3933L11.7201 7.50664C11.8534 7.63997 11.9134 7.80664 11.9134 7.97997V7.9733Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-[12px] h-[40px]">
        {shadowUpdating ? <Skeleton variant="rounded" height="100%" /> : <ApplyAllParagraphOptions />}
      </div>
      {/* <div className="mt-[12px]"></div> */}
      {/* <div className="min-h-[100px]" />
      <LangSelect />
      <div className="min-h-[20px]" />
      <VoiceSelect /> */}
    </div>
  )
}
