/* eslint-disable @typescript-eslint/no-shadow */
import { useParams } from 'react-router-dom'
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { projectState } from '../../recoil/project/atoms'
import { isCallSuccessProjectState, isLoadingProjectState, projectValueState } from '../../recoil/project/selectors'
import useEditorCallbacks from '../../recoil/texteditor/editor/useEditorCallbacks'
import {
  sentenceBoxAudioState,
  sentenceBoxOptionState,
  sentenceBoxState,
  SENTENCEBOX_CATEGORY,
} from '../../recoil/texteditor/sentenceBox/atoms'
import { isLoadingAudioState } from '../../recoil/texteditor/sentenceBox/selectors'

import AivatarClient from '../AivatarClient'
import { CALL_STATE } from '../constants'
import { sentenceBoxIdsState } from '../../recoil/texteditor/editor/atoms'
import { isPlayingState } from '../../recoil/audiocontroller/selectors'
import { focusedBoxValueState } from '../../recoil/texteditor/editor/selectors'

export default function useUpdateProject() {
  const { workspaceId, projectId } = useParams()
  /** Recoils */
  const [project, setProject] = useRecoilState(projectState)

  const setProjectApiState = useSetRecoilState(projectValueState({ key: 'apiState' }))
  const [shadowUpdating, setShadowUpdating] = useRecoilState(projectValueState({ key: 'shadowUpdating' }))
  const [, setDelayShadowUpdate] = useRecoilState(projectValueState({ key: 'delayShadowUpdate' }))

  const id = useRecoilValue(focusedBoxValueState({ category: SENTENCEBOX_CATEGORY.AUDIO, key: 'id' }))
  const isCallSuccess = useRecoilValue(isCallSuccessProjectState)
  const isLoading = useRecoilValue(isLoadingProjectState)
  const isPlaying = useRecoilValue(isPlayingState)
  const isAudioLoading = useRecoilValue(projectValueState({ key: 'isAudioLoading' }))
  const isLoadingProject = useRecoilValue(isLoadingProjectState)
  const isLoadingAudio = useRecoilValue(isLoadingAudioState({ id }))

  const { updateSentenceBoxesByFetch } = useEditorCallbacks()

  const fetch = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => async (projectTitle, shadow = false) => {
    console.log(
      'seop shadow',
      shadow,
      shadowUpdating,
      isLoading,
      isAudioLoading,
      isPlaying,
      isLoadingProject,
      isLoadingAudio,
    )
    if (shadow) {
      if (shadowUpdating || isLoading || isAudioLoading || isPlaying || isLoadingProject || isLoadingAudio) {
        setDelayShadowUpdate(true)
        return
      }
    }

    const ids = snapshot.getLoadable(sentenceBoxIdsState).getValue()

    transact_UNSTABLE(async ({ get }) => {
      const sentences = ids
        .map((id) => {
          const box = get(sentenceBoxState(id))
          const option = get(sentenceBoxOptionState(id))
          const audio = get(sentenceBoxAudioState(id))

          return {
            box,
            option,
            audio,
          }
        })
        .map((b) => {
          const sentenceBox = b.box
          const sentenceBoxOption = b.option
          const sentenceBoxAudio = b.audio
          const { voiceId, space, pitch, speed, volume, language } = sentenceBoxOption
          const sentence = {
            paragraph: 0,
            type: 'text',
            content: sentenceBox.text,
            language,
            attribute: {
              projectId: Number(projectId),
              voiceId,
              space,
              pitch,
              speed,
              volume,
              language,
            },
          }
          if (sentenceBoxOption.lexo) sentence.lexo = sentenceBoxOption.lexo
          if (sentenceBox.sentenceId) {
            // sentence.attribute["sentenceId"] = sentenceBox.sentenceId;
            sentence.id = sentenceBox.sentenceId
          }
          if (sentenceBoxAudio.audioId) sentence.attribute.audioId = sentenceBoxAudio.audioId
          return sentence
        })

      const body = {
        title: project.title,
        id: project.id,
        sentences,
        firstInterval: project.firstInterval ?? 0,
        aivatarId: project.avatarId,
        dressId: project.dressId,
        backgroundColor: project.backgroundColor,
      }

      try {
        if (!workspaceId) return

        if (projectTitle) body.title = projectTitle
        if (shadow) {
          setShadowUpdating(true)
        } else {
          setProjectApiState(CALL_STATE.FETCHING)
        }
        const res = await AivatarClient().post(`workspaces/${workspaceId}/projects`, body)
        const { data, status } = res
        if (status !== 200) throw new Error('Invalid Response Status')

        const {
          id,
          title,
          str,
          sentences: sentenceData,
          firstInterval,
          aivatarId: avatarId,
          dressId,
          backgroundColor,
        } = data
        setProject((prev) => ({
          ...prev,
          id,
          title,
          str,
          firstInterval,
          avatarId,
          dressId,
          backgroundColor,
        }))
        updateSentenceBoxesByFetch({ sentenceData })

        if (shadow) {
          setShadowUpdating(false)
        } else {
          setProjectApiState(CALL_STATE.SUCCESS)
        }
      } catch (error) {
        console.error(error)
        if (shadow) {
          setShadowUpdating(false)
        } else {
          setProjectApiState(CALL_STATE.ERROR)
          throw new Error(error)
        }
      }
    })
  })

  return {
    fetchProject: fetch,
    isLoading,
    isCallSuccess,
    shadowUpdating,
  }
}

export const updateProject = async ({ workspaceId, accessToken, body }) => {
  try {
    if (!workspaceId) return
    const res = await AivatarClient({
      accessToken,
    }).post(`workspaces/${workspaceId}/projects`, body)
    const { data, status } = res
    if (status !== 200) throw new Error('updateProject - Invalid Response Status')
    // eslint-disable-next-line consistent-return
    return data
  } catch (error) {
    throw new Error(error)
  }
}
