/* eslint-disable max-classes-per-file */

type PopupName = 'SUBSCRIPTION'

export class LocalStorage {
  private static itemNames = {
    NOT_SHOWN_POPUP_LIST: 'notShownPopupList',
    LAST_ACCESSED_WORKSPACE_ID: 'lastAccessedWorkspaceId',
    FAVORITE_VOICE_LIST: 'favoriteVoiceList',
  }

  static get notShownPopupList(): PopupName[] {
    const stringItem = window.localStorage.getItem(this.itemNames.NOT_SHOWN_POPUP_LIST)

    return stringItem ? JSON.parse(stringItem) : []
  }

  private static set notShownPopupList(popupList: PopupName[]) {
    window.localStorage.setItem(this.itemNames.NOT_SHOWN_POPUP_LIST, JSON.stringify(popupList))
  }

  static addNotShownPopupList(popupName: PopupName) {
    const nextPopupList = [...new Set([...this.notShownPopupList, popupName])]

    this.notShownPopupList = nextPopupList
  }

  static get lastAccessedWorkspaceId(): number | null {
    const stringItem = window.localStorage.getItem(this.itemNames.LAST_ACCESSED_WORKSPACE_ID)

    return stringItem ? Number(stringItem) : null
  }

  static set lastAccessedWorkspaceId(workspaceId: number | null) {
    if (!workspaceId) {
      window.localStorage.removeItem(this.itemNames.LAST_ACCESSED_WORKSPACE_ID)
    } else {
      window.localStorage.setItem(this.itemNames.LAST_ACCESSED_WORKSPACE_ID, String(workspaceId))
    }
  }

  static get favoriteVoiceList(): number[] {
    const stringItem = window.localStorage.getItem(this.itemNames.FAVORITE_VOICE_LIST)

    return stringItem ? JSON.parse(stringItem) : []
  }

  static set favoriteVoiceList(voiceList: number[]) {
    window.localStorage.setItem(this.itemNames.FAVORITE_VOICE_LIST, JSON.stringify(voiceList))
  }
}

export class SessionStorage {
  private static itemNames = {
    CLOSED_POPUP_LIST: 'closedPopupList',
  }

  static get closedPopupList(): PopupName[] {
    const stringItem = window.sessionStorage.getItem(this.itemNames.CLOSED_POPUP_LIST)

    return stringItem ? JSON.parse(stringItem) : []
  }

  private static set closedPopupList(popupList: PopupName[]) {
    window.sessionStorage.setItem(this.itemNames.CLOSED_POPUP_LIST, JSON.stringify(popupList))
  }

  static addClosedPopupList(popupName: PopupName) {
    const nextPopupList = [...new Set([...this.closedPopupList, popupName])]

    this.closedPopupList = nextPopupList
  }
}
