/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { workspaceQueries } from '../../../../../../../../services/workspace'
import VoiceItem from '../../components/VoiceItem'
import IconWarning from './IconWarning'
import useURI from '../../../../../../../../hooks/useURI'
import IconPlus from './IconPlus'

type Props = {
  filterTags: string[][]
}

export default function Customs({ filterTags }: Props) {
  const { workspaceId } = useParams()
  const { uri } = useURI()

  const { data } = useQuery({
    ...workspaceQueries.detail(Number(workspaceId))._ctx.voices,
    select: (data: any) =>
      data.voices
        .filter(
          (item: any) =>
            item.voice.type === 'CUSTOM' &&
            filterTags.every(
              (tagList: string[]) => tagList.length === 0 || tagList.some((tag) => item.voice.tags.includes(tag)),
            ),
        )
        .map((item: any) => item.voice),
  })

  return (
    <div className="h-full overflow-y-auto overflow-x-hidden px-[32px] py-[24px]" id="voice-area">
      {data.length === 0 ? (
        <div className="grid h-full place-items-center">
          <div className="flex flex-col items-center">
            <IconWarning />
            <div className="text-headline05 text-gs01-950 mt-[8px] text-center font-[500]">
              나만의 <b className="text-bcblue-500">맞춤형 목소리</b>를<br />
              만들어보세요!
            </div>
            <a
              href={uri['contact-us']}
              target="_blank"
              rel="noreferrer"
              className="text-button01 mt-[12px] inline-flex h-[40px] items-center rounded-[50px] bg-gradient-to-r from-[#006cff] to-[#18c95d] px-[20px] text-white"
            >
              커스텀 목소리 문의하기
            </a>
          </div>
        </div>
      ) : (
        <div className="mb-[25px] grid grid-cols-[309px_309px_309px_309px] gap-[16px]">
          <div className="before:from-bcblue-500 group relative w-[309px] before:absolute before:left-1/2 before:top-[14px] before:hidden before:h-[135px] before:w-[324px] before:-translate-x-1/2 before:bg-gradient-to-r before:to-green-400 before:opacity-30 before:blur-[29.5px] hover:before:block">
            <a
              href={uri['contact-us']}
              target="_blank"
              rel="noreferrer"
              className="border-gs01-200 relative z-10 flex h-[128px] items-center justify-center gap-[18px] rounded-[8px] border bg-white"
            >
              <IconPlus />
              <div className="text-gs01-900 text-headline05 whitespace-nowrap font-[700]">
                나만의 <span className="text-bcblue-500">맞춤형 목소리</span>를<br />
                만들어보세요!
              </div>
            </a>
          </div>
          {data
            .sort((a: any, b: any) => {
              if (a.gender === b.gender) {
                return a.displayName.localeCompare(b.displayName)
              } else {
                if (a.gender === 'male' && b.gender === 'female') {
                  return 1
                }

                return -1
              }
            })
            .map((item: any) => (
              <VoiceItem key={item.id} data={item} />
            ))}
        </div>
      )}
    </div>
  )
}
