import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import SentenceBoxOptionRoot from './SoundOptionRoot'
import { isAvatarEditorShowState } from '../../../recoil/navigator/selectors'
import VideoOptionRoot from './VideoOptionRoot'
import AivatarBackdrop from '../../../components/AivatarBackdrop'
import { editorValueState } from '../../../recoil/texteditor/editor/selectors'
import { isLoadingAudioState, isValidSentenceBoxState } from '../../../recoil/texteditor/sentenceBox/selectors'
import { isPlayingState } from '../../../recoil/audiocontroller/selectors'

function OptionManager() {
  const isAvatarEditorShow = useRecoilValue(isAvatarEditorShowState)

  const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))
  const isPlaying = useRecoilValue(isPlayingState)
  const isLoadingAudio = useRecoilValue(isLoadingAudioState({ id: focusedBoxId }))
  const isValidSentenceBox = useRecoilValue(isValidSentenceBoxState(focusedBoxId))

  /** Validation */

  const isAvailable = useMemo(
    () => !isPlaying && !isLoadingAudio && isValidSentenceBox,
    [isPlaying, isLoadingAudio, isValidSentenceBox],
  )

  return (
    <div className="option_width aivatar_scroll_wrapper bg-gs01-20 border-l-gs01-100 relative flex h-full flex-col items-center overflow-hidden overflow-y-auto border-l p-[32px]">
      <AivatarBackdrop isFullScreen={false} open={!isAvailable} backgroundColor="#ffffff60" />
      {isAvatarEditorShow ? <VideoOptionRoot /> : <SentenceBoxOptionRoot />}
    </div>
  )
}
export default OptionManager
