/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, mergeAttributes, Node } from '@tiptap/core'
import { EditorState, Plugin, TextSelection } from '@tiptap/pm/state'
import { EditorView } from '@tiptap/pm/view'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { getNextCursor, getPrevCursor, goToNextCursor, goToPrevCursor } from '../../utils/cursor'
import SentenceComponent from './SentenceComponent'

const deleteCharactersUtil = (state: EditorState, dispatch: Dispatch, cut: boolean = false) => {
  const { selection, tr } = state
  const { $from, $to } = selection

  const parentNode = $from.parent

  console.log(
    'Delete',
    'pos',
    $from.pos,
    'start',
    $from.start(),
    'end',
    $from.end(),
    'before',
    $from.before(),
    'after',
    $from.after(),
    'posAtIndex',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    $from.posAtIndex(),
    'index',
    $from.index(),
    'textOffset',
    $from.textOffset,
    'parentNode',
    parentNode.type.name,
    $to.pos,
    $to.end(),
  )

  // 범위선택 안한 상태로 문장의 커서가 처음에 있을 때
  if ($from.pos === $to.pos) {
    if (parentNode.type.name === 'sentence' && $from.pos === $from.start()) {
      const prevCursor = getPrevCursor(state)
      tr.delete(prevCursor.pos, $from.pos) // space 노드 삭제
      dispatch?.(tr.scrollIntoView())
      return true
    }
    // 빈 커서에 있는 경우 (박스 생성 안돼있음)
    if (parentNode.type.name === 'paragraph') {
      if ($from.pos === $from.end()) {
        // 빈 문단 단독일 경우의 빈 커서인 경우
        if ($from.start() === $from.end()) {
          return false
        }
        // 문장이 있는 상태로 마지막 빈 커서인 경우
        goToPrevCursor(state, dispatch)
        return true
      }

      // 커서가 문장사이 중간에 걸쳐있는 경우
      const prevCursor = getPrevCursor(state)
      const nextCursor = getNextCursor(state)
      tr.delete(prevCursor.pos, nextCursor.pos) // space 노드 삭제
      dispatch?.(tr.scrollIntoView())
      return true
    }
  } else {
    console.log('seop selection', parentNode.type.name, $from.pos, $from.start(), $to.pos, $to.end())
    // 문장 끝이 pos 하나가 더있다;;
    // if (parentNode.type.name === 'sentence' && $from.pos === $from.start() && $to.pos >= $to.end() - 1) {
    const prevCursor = getPrevCursor(state)
    const nextCursor = getNextCursor(state)
    console.log('seop prev next', prevCursor, nextCursor)

    if (cut) {
      // $from.pos부터 nextCursor.pos까지의 텍스트 추출
      const selectedText = state.doc.textBetween($from.pos, nextCursor.pos)

      // 클립보드에 텍스트 복사
      navigator.clipboard
        .writeText(selectedText)
        .then(() => {
          console.log('Text copied to clipboard:', selectedText)
        })
        .catch((err) => {
          console.error('Failed to copy text to clipboard:', err)
        })
    }

    if (prevCursor.parent.type.name === 'sentence' && nextCursor.parent.type.name === 'sentence') {
      tr.delete($from.pos, nextCursor.pos)
    } else {
      tr.delete(prevCursor.pos, nextCursor.pos)
    }

    // tr.delete(prevCursor.pos, nextCursor.pos) // space 노드 삭제
    dispatch?.(tr.scrollIntoView())
    // let prevCursor = getPrevCursor(state)
    // let nextCursor = getNextCursor(state)
    // console.log('seop prev next', prevCursor, nextCursor)

    // if (cut) {
    //   // $from.pos부터 nextCursor.pos까지의 텍스트 추출
    //   const selectedText = state.doc.textBetween($from.pos, nextCursor.pos)

    //   // 클립보드에 텍스트 복사
    //   navigator.clipboard
    //     .writeText(selectedText)
    //     .then(() => {
    //       console.log('Text copied to clipboard:', selectedText)
    //     })
    //     .catch((err) => {
    //       console.error('Failed to copy text to clipboard:', err)
    //     })
    // }

    // if ($to.parent.type.name === 'paragraph') {
    //   tr.delete(prevCursor.pos, nextCursor.pos) // space 노드 삭제
    // } else {
    //   tr.delete($from.pos, nextCursor.pos) // space 노드 삭제
    // }
    // // tr.delete(prevCursor.pos, nextCursor.pos) // space 노드 삭제
    // dispatch?.(tr.scrollIntoView())
    return true
    // }

    return false
  }

  return false
}

const Sentence = Node.create({
  name: 'sentence',

  group: 'inline',
  inline: true,
  content: 'text*',

  // addOptions() {
  //   return {
  //     enableInputRules: true,
  //   }
  // },

  // parseHTML() {
  //   return [{ tag: 'react-component' }]
  // },

  addAttributes() {
    return {
      'data-sentencebox-id': {
        parseHTML: (element) => element.getAttribute('data-sentencebox-id'),
      },
      'data-splitted': {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-splitted'),
      },
    }
  },

  renderHTML({ HTMLAttributes }) {
    // { node }
    // if (!node.textContent || node.textContent.trim() === '') {
    //   return ['br', 0]
    // }

    return ['sentence-component', mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(SentenceComponent)
  },

  addCommands() {
    return {
      splitSentence:
        () =>
        ({ state, dispatch }) => {
          const { selection, tr } = state
          const { $from, $to } = selection
          const parentNode = $from.parent

          console.log(
            'split',
            'pos',
            $from.pos,
            'start',
            $from.start(),
            'end',
            $from.end(),
            'before',
            $from.before(),
            'after',
            $from.after(),
            'posAtIndex',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            $from.posAtIndex(),
            'index',
            $from.index(),
            'textOffset',
            $from.textOffset,
            'parentNode',
            parentNode.type.name,
          )
          if ($from.pos === $to.pos) {
            // 현재 노드가 sentence인지 확인
            if (parentNode.type.name === 'sentence') {
              const offset = $from.parentOffset
              const nodeText = parentNode.textContent || ''

              // 커서가 마지막일 경우
              if (offset === nodeText.length) {
                // space나 sentence 만나면 다음커서로 지나가야됨. text(문장 중간에 커서 있었을 경우), paragraph(문장 마지막)를 만나야됨
                goToNextCursor(state, dispatch)
                return true
                // 커서가 중간일 경우
              }

              if (offset > 0) {
                const sentenceType = state.schema.nodes.sentence

                if (!sentenceType) throw Error('sentence 스키마가 정의되어있지 않습니다.')

                // 텍스트를 커서 기준으로 두 부분으로 나누기
                const firstPart = nodeText.slice(0, offset)
                const secondPart = nodeText.slice(offset)

                // 새로운 sentence 노드를 생성하여 두 번째 부분 삽입
                const newSentenceNode = sentenceType.create({ 'data-splitted': 'true' }, state.schema.text(secondPart))
                tr.insert($from.after(), newSentenceNode)

                // 기존 sentence를 첫 번째 부분으로 업데이트하고, 두 번째 부분으로 새 sentence 노드 추가
                tr.insertText(firstPart, $from.start(), $from.end()) // 기존 텍스트를 첫 번째 부분으로 수정

                // 새로운 텍스트가 삽입된 후, 커서를 다음 `sentence` 노드로 이동
                goToNextCursor(state, dispatch)
                return true
              }

              if (offset === 0) {
                //! 가장 위험한 코드 - 커서 위치를 -1로 이동 (기본 엔터 동작 적용을 위해)
                const newPos = Math.max(0, $from.pos - 1) // 경계값 방지
                const transaction = state.tr.setSelection(TextSelection.create(state.doc, newPos))
                dispatch?.(transaction)
                return false
              }
            }
          }

          return false
        },
      deleteCharacters:
        (cut: boolean = false) =>
        // eslint-disable-next-line arrow-body-style
        ({ state, dispatch }) => {
          return deleteCharactersUtil(state, dispatch, cut)
          // const { selection, tr } = state
          // const { $from, $to } = selection

          // const parentNode = $from.parent

          // console.log(
          //   'Delete',
          //   'pos',
          //   $from.pos,
          //   'start',
          //   $from.start(),
          //   'end',
          //   $from.end(),
          //   'before',
          //   $from.before(),
          //   'after',
          //   $from.after(),
          //   'posAtIndex',
          //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //   // @ts-ignore
          //   $from.posAtIndex(),
          //   'index',
          //   $from.index(),
          //   'textOffset',
          //   $from.textOffset,
          //   'parentNode',
          //   parentNode.type.name,
          //   $to.pos,
          //   $to.end(),
          // )

          // // 범위선택 안한 상태로 문장의 커서가 처음에 있을 때
          // if ($from.pos === $to.pos) {
          //   if (parentNode.type.name === 'sentence' && $from.pos === $from.start()) {
          //     const prevCursor = getPrevCursor(state)
          //     tr.delete(prevCursor.pos, $from.pos) // space 노드 삭제
          //     dispatch?.(tr.scrollIntoView())
          //     return true
          //   }
          //   // 빈 커서에 있는 경우 (박스 생성 안돼있음)
          //   if (parentNode.type.name === 'paragraph') {
          //     if ($from.pos === $from.end()) {
          //       // 빈 문단 단독일 경우의 빈 커서인 경우
          //       if ($from.start() === $from.end()) {
          //         return false
          //       }
          //       // 문장이 있는 상태로 마지막 빈 커서인 경우
          //       goToPrevCursor(state, dispatch)
          //       return true
          //     }

          //     // 커서가 문장사이 중간에 걸쳐있는 경우
          //     const prevCursor = getPrevCursor(state)
          //     const nextCursor = getNextCursor(state)
          //     tr.delete(prevCursor.pos, nextCursor.pos) // space 노드 삭제
          //     dispatch?.(tr.scrollIntoView())
          //     return true
          //   }
          // } else {
          //   console.log('seop selection', parentNode.type.name, $from.pos, $from.start(), $to.pos, $to.end())
          //   if (parentNode.type.name === 'sentence' && $from.pos === $from.start() && $to.pos === $to.end()) {
          //     const prevCursor = getPrevCursor(state)
          //     const nextCursor = getNextCursor(state)

          //     if (cut) {
          //       // $from.pos부터 nextCursor.pos까지의 텍스트 추출
          //       const selectedText = state.doc.textBetween($from.pos, nextCursor.pos)

          //       // 클립보드에 텍스트 복사
          //       navigator.clipboard
          //         .writeText(selectedText)
          //         .then(() => {
          //           console.log('Text copied to clipboard:', selectedText)
          //         })
          //         .catch((err) => {
          //           console.error('Failed to copy text to clipboard:', err)
          //         })
          //     }

          //     if ($to.parent.type.name === 'paragraph') {
          //       tr.delete(prevCursor.pos, nextCursor.pos) // space 노드 삭제
          //     } else {
          //       tr.delete($from.pos, nextCursor.pos) // space 노드 삭제
          //     }
          //     // tr.delete(prevCursor.pos, nextCursor.pos) // space 노드 삭제
          //     dispatch?.(tr.scrollIntoView())
          //     return true
          //   }

          //   return false
          // }

          // return false
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => editor.commands.splitSentence(),
      Backspace: ({ editor }) => editor.commands.deleteCharacters(),
      'Mod-x': ({ editor }) => editor.commands.deleteCharacters(true),
    }
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            cut: (view, event) => deleteCharactersUtil(view.state, view.dispatch, true),
          },
        },
      }),
    ]
  },
})

export default Sentence
