/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as ResetIcon } from '../../../../../../assets/images/icon-reset.svg'
import { SENTENCEBOX_CATEGORY } from '../../../../../../recoil/texteditor/sentenceBox/atoms'
import {
  focusedBoxValueState,
  selectedBoxIdsState,
  selectedBoxValueState,
} from '../../../../../../recoil/texteditor/editor/selectors'
import useEditorCallbacks from '../../../../../../recoil/texteditor/editor/useEditorCallbacks'
import OptionSettingModal from '../OptionSettingModal'
import useAivatarModal from '../../../../../../hooks/useAivatarModal'
import { sentenceBoxOptionValueState } from '../../../../../../recoil/texteditor/sentenceBox/selectors'
import useAivatarDidMount from '../../../../../../hooks/useAivatarDidMount'
import useUpdateProject from '../../../../../../services/project/useUpdateProject'
import AivatarButton from '../../../../../../components/buttons/AivatarButton'
import useDialog from '../../../../../../hooks/useDialog'
import AivatarButtonV2 from '../../../../../../components/buttons/AivatarButtonV2'
import IconReset from './IconReset'

const UPDATE_PROJECT_ACTION_TYPE = {
  NEW: 'NEW',
  UPDATE: 'UPDATE',
}

function ResetOptions() {
  const [actionType, setActionType] = useState(UPDATE_PROJECT_ACTION_TYPE.NEW)
  const { t } = useTranslation('project-option')

  /** Recoilds */
  const { resetSentenceBoxOptions } = useEditorCallbacks()
  // const focusedBoxId = useRecoilValue(focusedBoxValueState({ category: SENTENCEBOX_CATEGORY.GENERAL, key: 'id' }))
  const space = useRecoilValue(selectedBoxValueState({ category: SENTENCEBOX_CATEGORY.OPTION, key: 'space' }))
  const speed = useRecoilValue(selectedBoxValueState({ category: SENTENCEBOX_CATEGORY.OPTION, key: 'speed' }))
  const pitch = useRecoilValue(selectedBoxValueState({ category: SENTENCEBOX_CATEGORY.OPTION, key: 'pitch' }))
  const volume = useRecoilValue(selectedBoxValueState({ category: SENTENCEBOX_CATEGORY.OPTION, key: 'volume' }))

  /** Services */
  const { fetchProject, shadowUpdating } = useUpdateProject()

  /** Memos */
  const isActiveButton = useMemo(() => {
    // if (!focusedBoxId) return false
    if (shadowUpdating) return false
    if (space === 0.5 && speed === 1 && pitch === 1 && volume === 1) return false
    return true
  }, [space, speed, pitch, volume, shadowUpdating])

  const dialog = useDialog()

  const selectedSentenceBoxIds = useRecoilValue(selectedBoxIdsState)

  /** events */
  const onClick = () => {
    if (!isActiveButton) return
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // openOptionSettingModal()
    dialog.confirm({
      title: `${selectedSentenceBoxIds.length}개의 문장 옵션 초기화`,
      message: `${selectedSentenceBoxIds.length}개의 문장 옵션을 전부 초기화 하시겠습니까?`,
      confirmButtonName: '초기화',
      onClickConfirm: onClickReset,
    })
  }

  // const onClickClose = () => {
  //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //   closeOptionSettingModal()
  // }

  const onClickReset = async () => {
    resetSentenceBoxOptions()
    setActionType(UPDATE_PROJECT_ACTION_TYPE.UPDATE)
    dialog.close()
  }

  /** Modal */
  // const {
  //   modalComponent: OptionSettingModalComponent,
  //   openModal: openOptionSettingModal,
  //   closeModal: closeOptionSettingModal,
  // } = useAivatarModal({
  //   children: (
  //     <OptionSettingModal
  //       titleComponent={
  //         <p className="mb-[20px] px-[45px] text-center text-[24px] font-bold">
  //           <Trans t={t}>
  //             정말 모든 설정을
  //             <br />
  //             <span className="main_c">초기화</span>
  //             하시겠습니까?
  //           </Trans>
  //         </p>
  //       }
  //       subTitle={t('초기화를 누르면 모든 설정이 초기화됩니다.')}
  //       onClickSetting={onClickReset}
  //       onClickClose={onClickClose}
  //       setButtonText={t('초기화')}
  //     />
  //   ),
  //   closeOnDocumentClick: true,
  // })

  /** Action Effects */
  useAivatarDidMount(async () => {
    if (actionType !== UPDATE_PROJECT_ACTION_TYPE.UPDATE) return
    setActionType(UPDATE_PROJECT_ACTION_TYPE.NEW)
    try {
      await fetchProject()
    } catch (error) {
      /* empty */
    }
    // closeOptionSettingModal()
  }, [actionType])

  return (
    <AivatarButtonV2
      type="button"
      variant="outlined-rounded"
      size="M"
      className="text-gs01-400 [&_path]:fill-gs01-400 border-gs01-400"
      disabled={!isActiveButton}
      onClick={onClick}
    >
      {/* {OptionSettingModalComponent} */}
      <span className="mr-[5px] text-[14px] font-medium">문장 옵션 초기화</span>
      <IconReset />
    </AivatarButtonV2>
  )
}

export default ResetOptions
